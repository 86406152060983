.hero {
	align-content: center;
	align-items: center;
	background-color: #fff;
	background-image: url("../i/image-placeholder.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	border-top: .125em solid #dee2e4;
	display: flex;
	justify-content: center;
	margin: 0;

	/**
	 * we need a "loaded" class forlazy-loaidng the images
	 */

	&__home .hero.loaded {
		background-position: 50% 50%;
		/*
		background-position: 0 100%;
		background-image: linear-gradient(rgba(255, 255, 255, .6), rgba(255, 255, 255, .6)), url("../i/aud-crp2.jpg");
		*/
		background-image: linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .6)), url("../i/hp2a-opt.jpg");

		@media (--lg) {
			/*
			background-position: 0 35%;
			background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, .8) 40%, rgba(255, 255, 255, .1) 80%), linear-gradient(180deg, rgba(80, 30, 0, .5), rgba(0, 0, 0, 0)), url("../i/aud-crp2.jpg");
			*/
			background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, .8) 30%, rgba(255, 255, 255, .1) 80%), linear-gradient(180deg, rgba(80, 30, 0, .5), rgba(0, 0, 0, 0)), url("../i/hp2a-opt.jpg");
		}
	}

	&__about .hero.loaded {
		background-position: 0 20%;
		background-image: linear-gradient(rgba(255, 255, 255, .6), rgba(255, 255, 255, .6)), url("../i/teacher-cropped.jpg");

		@media (--lg) {
			background-image: radial-gradient(circle at center, rgba(206, 212, 212, .8) 33%, rgba(255, 255, 255, 0) 60%), linear-gradient(180deg, rgba(0, 20, 70, .1), rgba(255, 255, 255, 0)), url("../i/teacher-cropped.jpg");
		}
	}

	&__mysurveys .hero.loaded,
	&__data .hero.loaded {
		background-image: linear-gradient(rgba(255, 255, 255, .8), rgba(255, 255, 255, .2)), url("../i/data.jpg");
		@media (--lg) {
			background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, .7) 40%, rgba(255, 255, 255, 0) 70%), linear-gradient(180deg, rgba(255, 255, 255, .5), rgba(0, 0, 0, 0)), url("../i/data.jpg");
		}
	}

	&__surveys .hero.loaded {
		background-position: 0 0;
		background-image: linear-gradient(rgba(255, 250, 245, .7), rgba(255, 250, 245, .7)), url("../i/class.jpg");

		@media (--lg) {
			background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, .8) 40%, rgba(255, 255, 255, 0) 70%), linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)), url("../i/class.jpg");
		}
	}

	&__resources .hero.loaded {
		background-position: 0 0;
		background-image: linear-gradient(rgba(255, 250, 245, .9), rgba(255, 250, 245, .2)), url("../i/resources.jpg");

		@media (--lg) {
			background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, .4) 40%, rgba(255, 255, 255, 0) 70%), linear-gradient(90deg, rgba(20, 20, 0, .1), rgba(0, 20, 80, .2)), url("../i/resources.jpg");
		}
	}

	&__wrap {
		margin: 0 auto;
		padding: 0;
		position: relative;

		@media (--lg) {
			background-color: unset;
			background-image: none;
		}

	}

	/*
	object-fit: contain;
	width: 100%;
	*/
}

/*
.home .cta {
	h1 {
		color: var(--mast2);
	}

	.entry {
		color: #333;
		font-size: 1em;
		text-align: center;
	}

	span {
		background: rgba(255, 255, 255, .8);
		box-shadow: .25em 0 0 rgba(255, 255, 255, .8), -.25em 0 0 rgba(255, 255, 255, .8);
		box-decoration-break: clone;
	}
}
*/

.hero__cta {
	flex: 1;
	margin: 8vw 0;
	padding: .5rem 0;
	position: relative;
	text-align: center;

	h1,
	.entry {
		margin: .5rem auto;
		max-width: 50rem;
		padding: .125em .5rem;
	}

	h1 {
		color: var(--mast2);
		/*
		color: var(--mast2);
		color: #222;
		*/
		font-family: var(--font-heading);
		/*
		font-size: 1.8em;
		font-size: calc(1.3em * 1.3vw);
		*/
		font-size: 1.8em;
		font-weight: 600;
		/*
		font-weight: 500;
		text-align: left;
		*/


		@media (--lg) {
			font-size: 2.5em;
		}
	}

	/*
	.entry {
		line-height: 1.4;
		font-weight: 600;
	}

	span {
		background: rgba(0, 0, 0, .6);
		box-shadow: .25em 0 0 rgba(0, 0, 0, .6), -.25em 0 0 rgba(0, 0, 0, .6);
		box-decoration-break: clone;
	}
	*/
	.entry {
		color: #333;
		font-size: 1em;
		font-weight: 600;
		/*
		text-align: center;
		*/
	}

	.entry span {
		background: rgba(255, 255, 255, .7);
		box-shadow: .25em 0 0 rgba(255, 255, 255, .7), -.25em 0 0 rgba(255, 255, 255, .7);
		box-decoration-break: clone;
	}

	a {
		background: var(--mast2);
		border: .05em solid transparent;
		border-radius: .25rem;
		color: #fff;
		display: block;
		font-size: 1.2em;
		font-weight: 600;
		/*
		padding: .5em 1em;
		*/
		padding: .5em;
		text-decoration: none;
		text-shadow: none;
		transition: all .25s;

		@media (--med) {
			display: inline-block;
			word-wrap: nowrap;
		}

		&:focus,
		&:hover {
			background-image: unset;
			background-color: #fff;
			border-color: var(--mast2);
			color: var(--mast2);
		}
	}

	ul {
		align-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		list-style: none;
		flex: 1;
		margin: 0;
		padding: 0;
		text-align: center;
		width: 100%;
	}

	li {
		flex: 1 1 100%;
		margin: .5em;
		max-width: 300px;

		/*
		&:first-child a {
		*/

		/*
		&:nth-child(1n+0) a {
			background-color: rgb(110, 195, 20);
			&:focus,
			&:hover {
				border-color: rgb(110, 195, 20);
				color: color(rgb(110, 195, 20) b(50%));
			}
		}

		&:nth-child(2n+0) a {
			background-color: rgb(60, 150, 225);
			&:hover {
				border-color: rgb(60, 150, 225);
				color: color(rgb(60, 150, 225) b(25%));
			}
		}

		&:nth-child(3n+0) a {
			background-color: #ff4350;
			&:hover {
				border-color: #ff4350;
				color: color(#ff4350 b(25%));
			}
		}
		*/

		a {
			&:focus,
			&:hover {
				background-color: #fff;
			}
		}

		@media (--med) {
			flex: 0 0 auto;
			margin: 1em;
		}
	}


	@media (--med) {
		font-size: 1.2em;
	}

	/*
	@media (--lg) {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;

		p,
		h1,
		.entry {
			max-width: 40em;
		}
	}
	*/

}

